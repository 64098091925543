;(($, window, document, undefined) => {

    $.extend(yunu, {
        sub_account_editor(id){
            let config = {};

            if (id) {
                config = Object.assign(config, {
                    title: '编辑',
                    url: '/' + module_name + '/User/sub_account_edit',
                    get_url: '/' + module_name + '/Api/get_userinfo',
                    select_url: '/' + module_name + '/Api/get_site_select/uid/' + id,
                });
            } else {
                config = Object.assign(config, {
                    title: '新增',
                    url: '/' + module_name + '/User/sub_account_add',
                    select_url: '/' + module_name + '/Api/get_site_select',
                });
            }

            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    ${id ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">账号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" ${id ? `value="${data.username}" disabled` : 'name="username" required lay-verify="required"'}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="password" name="password" placeholder="请输入密码" ${id ? '' : 'required lay-verify="required"'}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">QQ</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="qq" placeholder="请输入QQ号码" ${id ? `value="${data.qq}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">域名</label>
                        <div class="layui-input-inline w334">
                            <select name="site_id" xm-select="site_id" xm-select-search="">
                                <option value="">请选择域名</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">扣费比例</label>
                        <div class="layui-input-block">
                            <input type="radio" name="points_multiple" value="1" title="1" ${data.points_multiple == 1 ? 'checked' : ''}>
                            <input type="radio" name="points_multiple" value="2" title="2" ${data.points_multiple == 2 || !data.points_multiple ? 'checked' : ''}>
                            <input type="radio" name="points_multiple" value="5" title="5" ${data.points_multiple == 5 ? 'checked' : ''}>
                            <input type="radio" name="points_multiple" value="10" title="10" ${data.points_multiple == 10 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            let editor = (res = {}) => {
                if (!layui.hasOwnProperty('formSelects')) {
                    layui.config({
                        base: '/Public/Public/js/'
                    }).extend({
                        formSelects: 'formSelects-v4.min'
                    });
                }

                layui.use(['layer', 'form', 'formSelects'], function(){
                    let layer = layui.layer;
                    let form = layui.form;
                    let formSelects = layui.formSelects;

                    layer.open({
                        type: 1,
                        title: config.title,
                        area: ['566px'],
                        content: tpl(res.data),
                        success: function () {
                            form.render();

                            layui.formSelects.data('site_id', 'server', {
                                url: config.select_url
                            });
                            $('.layui-layer-content').css('overflow', 'visible');

                            form.on('submit(*)', function(data){
                                yunu.post({
                                    url: config.url,
                                    field: data.field,
                                    success: function (res) {
                                        layer.closeAll('page');
                                        yunu.msg(res.info, {
                                            time: 1000,
                                            end: function () {
                                                window.location.reload();
                                            }
                                        });
                                    }
                                });
                                return false;
                            });
                        }
                    });
                });
            }

            if (id) {
                yunu.post({
                    url: config.get_url,
                    field: {uid: id},
                    success: function (res) {
                        editor(res);
                    }
                });
            } else {
                editor();
            }
        },
        monitor_add(){
            let tpl = (data = []) => {
                return `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">域名</label>
                    <div class="layui-input-inline">
                        <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required">
                            <option value="">请选择域名</option>
                        </select>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">百度名称</label>
                    <div class="layui-input-inline">
                        <input class="layui-input" type="text" name="baidu_name" placeholder="请输入账号">
                    </div>
                    <div class="layui-form-mid layui-word-aux">原熊掌号名称，不填写会造成监控不准确</div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">监控点</label>
                    <div class="layui-input-block">
                        ${data.map((v, i) => `<input type="radio" name="monitor_point" value="${v.id}" title="${v.name}">`).join('')}
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">监控点</label>
                    <div class="layui-input-block">
                        <input type="checkbox" name="search_engines[]" lay-skin="primary" value="1" title="百度">
                        <input type="checkbox" name="search_engines[]" lay-skin="primary" value="2" title="百度移动">
                        <input type="checkbox" name="search_engines[]" lay-skin="primary" value="3" title="搜狗移动">
                        <input type="checkbox" name="search_engines[]" lay-skin="primary" value="4" title="好搜">
                        <input type="checkbox" name="search_engines[]" lay-skin="primary" value="5" title="神马">
                        <input type="checkbox" name="search_engines[]" lay-skin="primary" value="6" title="必应">
                    </div>
                </div>
                <div class="layui-form-item layui-form-text">
                    <label class="layui-form-label">关键词</label>
                    <div class="layui-input-block">
                        <textarea class="layui-textarea" name="keywords" required lay-verify="required" placeholder="一行一个，每个网站最多监控20个词，提交后无法修改，请谨慎提交"></textarea>
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                    <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                    </div>
                </div>
            </form>`
            };
            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;

                yunu.post({
                    url: '/' + module_name + '/Api/get_mp_list',
                    success: function (res) {
                        layer.open({
                            type: 1,
                            title: '新增',
                            area: ['780px'],
                            content: tpl(res.data)
                        });

                        form.render();

                        layui.formSelects.data('sid', 'server', {
                            url: '/' + module_name + '/Api/get_site_select'
                        });
                        $('.layui-layer-content').css('overflow', 'visible');


                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Monitor/add',
                                field: data.field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        push_add(){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">域名</label>
                    <div class="layui-input-inline">
                        <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required">
                            <option value="">请选择域名</option>
                        </select>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">分类名称</label>
                    <div class="layui-input-inline">
                        <input class="layui-input" type="text" name="cname" placeholder="请输入分类名称" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                    <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                    </div>
                </div>
            </form>`;

            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;

                layer.open({
                    type: 1,
                    title: '新增',
                    area: ['461px'],
                    content: tpl
                });

                form.render();

                layui.formSelects.data('sid', 'server', {
                    url: '/' + module_name + '/Api/get_site_select'
                });
                $('.layui-layer-content').css('overflow', 'visible');

                form.on('submit(*)', function(data){
                    yunu.post({
                        url: '/' + module_name + '/Push/add',
                        field: data.field,
                        success: function (res) {
                            layer.closeAll('page');
                            yunu.msg(res.info, {
                                time: 1000,
                                end: function () {
                                    window.location.reload();
                                }
                            });
                        }
                    });
                    return false;
                });
            });
        },
        push_section_editor(config = {}){
            config = Object.assign({
                section_word_min: 50,
                section_word_max: 300,
                nokeywords: []
            }, config);
            let tpl = `<form id="form" class="layui-form pd30">
                ${config.id ? `<input type="hidden" name="id" value="${config.id}">` : ''}
                <input type="hidden" name="pid" value="${config.pid}">
                <div class="layui-form-item">
                    <input type="radio" name="type" value="1" title="首段" ${config.type == 1 ? 'checked=""' : ''}>
                    <input type="radio" name="type" value="0" title="中段" ${!config.type ? 'checked=""' : ''}>
                    <input type="radio" name="type" value="2" title="尾段" ${config.type == 2 ? 'checked=""' : ''}>
                </div>
                <div class="layui-form-item">
                    <div class="clearfix">
                        <div class="fl insert_btn">
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【地区】</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【前缀】</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【主词】</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【后缀】</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【标题】</button>
                        </div>
                        <div class="fr">
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="collect">智能采集</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="reidf">去除标识</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="contraband">去除违禁词</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="replace">词语替换</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="rule">伪原创</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="reorganize">一键整理</button>
                        </div>
                    </div>
                </div>
                <div class="layui-form-item" id="content_item">
                    <textarea id="content" name="content"></textarea>
                </div>
                <div class="layui-form-item">
                    <div class="clearfix">
                        <div class="fl">
                            文字字数：<span class="color2" id="content_number">0</span> 字
                        </div>
                        <div class="fr">
                            <button class="layui-btn layui-btn-sm color_btn" lay-submit lay-filter="*">保存段落</button>
                        </div>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                let myCodeMirror;
                let min_max = [
                    {min: config.section_word_min, max: config.section_word_max},
                    {min: 50, max: 300},
                    {min: 20, max: 100},
                ];
                let value_validate = function (data, type) {
                    return data == '' || data.length < min_max[type]['min'] || data.length > min_max[type]['max'];
                }
                let set_error_info = function (data, type) {
                    $('#content_item').find('p').remove();
                    $('#content_item').append('<p class="pt10 c_f00">提示：您提交的字数不符合要求，请大于'+min_max[type]['min']+'字小于'+min_max[type]['max']+'字，高亮内容为 '+data.length+' 字</p>')
                }
                let data_filter = function (value, type) {
                    let unqualified = []; // 不合要求的数据
                    let qualified_value = []; // 拆分后满足要求的数据
                    value = value.filter(function (v, i) {
                        if (!value_validate(v, type)) {
                            return true;
                        } else if (v.length > min_max[type]['max']) {
                            v = v.split(/,|，/);
                            for (let i = 0; i < v.length; i++) {
                                if (!value_validate(v[i], type)) {
                                    qualified_value.push(v[i]);
                                } else {
                                    unqualified.push(v[i]);
                                }
                            }
                        } else {
                            unqualified.push(v);
                        }
                        return false;
                    });
                    value = value.concat(qualified_value);
                    let str = '';
                    while (unqualified.length > 1) {
                        if (str === '') {
                            str = unqualified.shift();
                        } else {
                            let qualified = false;
                            for (let i = 0; i < unqualified.length; i++) {
                                let data = str + unqualified[i];
                                if (data.length < min_max[type]['max']) {
                                    str += '，' + unqualified.splice(i, 1);
                                    qualified = true;
                                    break;
                                }
                            }
                            if (!qualified) {
                                str = unqualified.shift();
                            }
                        }
                        for (let i = 0; i < unqualified.length; i++) {
                            let data = str + '，' + unqualified[i] + '。';
                            if (!value_validate(data, type)) {
                                value.push(str + '，' + unqualified.splice(i, 1));
                                str = '';
                                break;
                            }
                        }
                    }
                    for (let i = 0; i < value.length; i++) {
                        value[i] += '。';
                    }
                    return value;
                }

                let nokeywords_group = [];
                layer.open({
                    type: 1,
                    title: '添加段落',
                    area: ['1000px', '570px'],
                    content: tpl,
                    success: function () {
                        form.render();
                        let content_number = $('#content_number');
                        CodeMirror.defineMode("nokeywords-mode", function(){
                            return {
                                token: function(stream, state) {
                                    for (let i = 0; i < config.nokeywords.length; i++) {
                                        if (stream.match(config.nokeywords[i]) ) {
                                            nokeywords_group.push(config.nokeywords[i]);
                                            return 'error';
                                        }
                                    }
                                    stream.next();
                                    return null;
                                }
                            };
                        });
                        myCodeMirror = CodeMirror.fromTextArea(document.getElementById("content"), {
                            mode: "nokeywords-mode",
                            lineNumbers: true,
                            lineWrapping: true
                        });
                        myCodeMirror.on('beforeChange', function(editor) {
                            nokeywords_group = [];
                        });
                        myCodeMirror.on('changes', function(editor) {
                            content_number.html(editor.getValue().length);
                        });
                        if (config.content) {
                            myCodeMirror.setValue(config.content);
                        }
                    }
                });

                // 插入标签
                $('.insert_btn button').on('click', function(event) {
                    event.preventDefault();
                    myCodeMirror.replaceSelection(this.innerHTML);
                });

                // 智能采集
                let collect_index;
                $('#collect').on('click', function(event) {
                    event.preventDefault();
                    let collect_storage = localStorage.getItem('collect');
                    collect_storage = collect_storage ? JSON.parse(collect_storage) : {};
                    let storage = Object.assign({
                        type: 1,
                        url: '',
                        list_start: '',
                        list_end: '',
                        show_start: '',
                        show_end: '',
                    }, collect_storage);
                    collect_index = layer.open({
                        type: 1,
                        title: '智能采集',
                        area: ['625px', '184px'],
                        content: `<form class="layui-form pd20" id="collect_form">
                            <input type="hidden" name="type" value="${storage.type}" />
                            <!--<div class="layui-form-item">
                                <div class="layui-input-inline">
                                    <input type="radio" name="type" value="0" title="一键采集" lay-filter="collect_type" ${storage.type == 0 ? 'checked=""' : ''}>
                                    <input type="radio" name="type" value="1" title="规则采集" lay-filter="collect_type" ${storage.type == 1 ? 'checked=""' : ''}>
                                </div>
                            </div>-->
                            <div class="layui-form-item">
                                <div class="layui-input-inline">
                                    <input type="radio" name="charset" value="0" title="UTF8" checked>
                                    <input type="radio" name="charset" value="1" title="GB2312">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-inline" style="width: 470px;">
                                    <input type="text" name="url" lay-verify="required" placeholder="" class="layui-input" value="${storage.url}">
                                    <button class="layui-btn btn submit" lay-submit="collect" lay-filter="collect">一键采集</button>
                                </div>
                            </div>
                            <div class="collect_type_item" ${storage.type == 1 ? '' : 'style="display: none;"'}>
                                <div class="layui-form-item">
                                    <div class="layui-inline">
                                    <label class="layui-form-label">列表起始标签</label>
                                        <div class="layui-input-inline">
                                            <input type="tel" name="list_start" class="layui-input">
                                        </div>
                                    </div>
                                    <div class="layui-inline">
                                        <label class="layui-form-label">列表结束标签</label>
                                        <div class="layui-input-inline">
                                            <input type="text" name="list_end" class="layui-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="layui-form-item">
                                    <div class="layui-inline">
                                    <label class="layui-form-label">详情起始标签</label>
                                        <div class="layui-input-inline">
                                            <input type="tel" name="show_start" class="layui-input">
                                        </div>
                                    </div>
                                    <div class="layui-inline">
                                        <label class="layui-form-label">详情结束标签</label>
                                        <div class="layui-input-inline">
                                            <input type="text" name="show_end" class="layui-input">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>`,
                        success: function(layero, index){
                            $('#collect_form').find('input[name="list_start"]').val(storage.list_start)
                            .end().find('input[name="list_end"]').val(storage.list_end)
                            .end().find('input[name="show_start"]').val(storage.show_start)
                            .end().find('input[name="show_end"]').val(storage.show_end);
                            form.render();
                            if (storage.type == 1) {
                                layer.style(index, {
                                    height: '300px'
                                });
                            }
                        }
                    });
                });
                form.on('radio(collect_type)', function(data){
                    if (data.value == 1) {
                        $('.collect_type_item').show();
                        layer.style(collect_index, {
                            height: '300px'
                        });
                    } else {
                        $('.collect_type_item').hide();
                        layer.style(collect_index, {
                            height: '184px'
                        });
                    }
                });
                form.on('submit(collect)', function(data){
                    yunu.load();
                    localStorage.setItem('collect', JSON.stringify(data.field));
                    $.post('/' + module_name + '/Api/collect', data.field, function (res) {
                        if (res.status == 1) {
                            yunu.msg(res.msg);
                            myCodeMirror.setValue(res.content);
                            layer.close(collect_index);
                        } else {
                            yunu.msg(res.msg, 'error');
                        }
                    }).always(function() {
                        yunu.load('close');
                    });
                    return false;
                });

                // 去除标识
                $('#reidf').on('click', function(event) {
                    event.preventDefault();
                    let value = myCodeMirror.getValue();
                    value = value.replace(/[（|\()]?[0-9|一|二|三|四|①|②|③|⑩]+[）|\)]?[、|，|,]?/g, '');
                    myCodeMirror.setValue(value);
                });

                // 去除违禁词
                $('#contraband').on('click', function(event) {
                    event.preventDefault();
                    let value = myCodeMirror.getValue();
                    yunu.load();
                    $.post('http://tool.yunucms.com/Api/contraband_replace', {content: value}, function(res) {
                        if (res.state == 200) {
                            myCodeMirror.setValue(res.data);
                        } else {
                            yunu.msg(res.msg, 'error');
                        }
                    }).always(function() {
                        yunu.load(close);
                    });
                });

                // 词语替换
                let replace_index;
                $('#replace').on('click', function(event) {
                    event.preventDefault();
                    replace_index = layer.open({
                        type: 1,
                        title: '词语替换',
                        area: ['490px'],
                        content: `<form class="layui-form" id="replace_form" style="padding: 20px 95px 0 0;">
                            <div class="layui-form-item">
                                <label class="layui-form-label">词语</label>
                                <div class="layui-input-block">
                                    <input type="text" name="word" placeholder="被替换的词语" class="layui-input">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">替换</label>
                                <div class="layui-input-block">
                                    <input type="text" name="replace" placeholder="用作替换的词语" class="layui-input">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                    <button class="layui-btn btn" lay-submit="" lay-filter="replace">立即提交</button>
                                </div>
                            </div>
                        </form>`
                    });
                });
                form.on('submit(replace)', function(data){
                    let value = myCodeMirror.getValue();
                    value = value.replace(new RegExp(data.field.word,'g'), data.field.replace);
                    myCodeMirror.setValue(value);
                    yunu.msg('替换完成');
                    layer.close(replace_index);
                    return false;
                });

                // 伪原创
                $('#rule').on('click', function (event) {
                    event.preventDefault();
                    let value = myCodeMirror.getValue();
                    yunu.load();
                    $.post('http://wyc.yunucms.com/api/index/feg', {type: 1, percent: 1, content: value}, function(res) {
                        if (res.state == 200) {
                            myCodeMirror.setValue(res.data);
                        } else {
                            yunu.msg(res.msg, 'error');
                        }
                    }).always(function() {
                        yunu.load(close);
                    });
                });

                // 一键整理
                $('#reorganize').on('click', function(event) {
                    event.preventDefault();
                    let value = myCodeMirror.getValue();
                    value = value.replace(new RegExp('\u00A0|\u0020|\u3000','g'), '');
                    value = yunu.val2arr(value);
                    value = data_filter(value, 0);
                    myCodeMirror.setValue(value.join('\n'));
                });

                form.on('submit(*)', function(data){
                    nokeywords_group = Array.from(new Set(nokeywords_group));
                    if (nokeywords_group.length) {
                        yunu.msg('失败，含有违禁词---  ' + nokeywords_group.join(','), 'error');
                        return false;
                    }
                    let value = myCodeMirror.getValue();
                    if (value.match(/\u00A0|\u0020|\u3000/) !== null) {
                        yunu.msg('不允许有非法字符！请使用一键整理或词语替换进行修改', 'error');
                        return false;
                    }
                    if (data.field.id > 0) {
                        // 修改
                        if (value_validate(value, 0)) {
                            myCodeMirror.addLineClass(0, 'wrap', 'error');
                            set_error_info(value, 0);
                            return false;
                        } else {
                            myCodeMirror.removeLineClass(0, 'wrap', 'error');
                        }
                    } else {
                        // 添加
                        value = value.split('\n');
                        for (let i = 0; i < value.length; i++) {
                            if (value_validate(value[i], 0)) {
                                myCodeMirror.addLineClass(i, 'wrap', 'error');
                                set_error_info(value[i], 0);
                                return false;
                            } else {
                                myCodeMirror.removeLineClass(i, 'wrap', 'error');
                            }
                        }
                    }
                    data.field.content = value;
                    yunu.post({
                        url: config.url,
                        field: data.field,
                        success: function (res) {
                            layer.closeAll('page');
                            yunu.msg(res.info, {
                                time: 1000,
                                end: function () {
                                    if (typeof config.success == 'function') {
                                        config.success();
                                    }
                                }
                            });
                        }
                    });
                    return false;
                });
            });
        },
        push_template_editor(config = {}){
            config = Object.assign({
                template_word_min: 600,
                template_word_max: 2000,
                template_title_min: 0,
                template_img_min: 0,
                template_section_min: 0,
                nokeywords: []
            }, config);
            let tpl = `<form id="form" class="layui-form pd30">
                ${config.id ? `<input type="hidden" name="id" value="${config.id}">` : ''}
                <input type="hidden" name="pid" value="${config.pid}">
                <div class="layui-form-item">
                    <div class="clearfix">
                        <div class="fl insert_btn">
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【标题】</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【图片】</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10">【段落】</button>
                        </div>
                        <div class="fr">
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="reidf">整理格式</button>
                            <button class="layui-btn layui-btn-sm color_btn pl10 pr10" id="replace">替换词语</button>
                        </div>
                    </div>
                </div>
                <div class="layui-form-item" id="content_item">
                    <textarea id="content" name="content"></textarea>
                </div>
                <div class="layui-form-item">
                    <div class="clearfix">
                        <div class="fl">
                            文字字数：<span class="color2" id="content_number">0</span> 字
                        </div>
                        <div class="fr">
                            <button class="layui-btn layui-btn-sm color_btn" lay-submit lay-filter="*">保存模板</button>
                        </div>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                let myCodeMirror;

                let nokeywords_group = [];
                layer.open({
                    type: 1,
                    title: '添加模板',
                    area: ['1000px', '520px'],
                    content: tpl,
                    success: function () {
                        let content_number = $('#content_number');
                        CodeMirror.defineMode("nokeywords-mode", function(){
                            return {
                                token: function(stream, state) {
                                    for (let i = 0; i < config.nokeywords.length; i++) {
                                        if (stream.match(config.nokeywords[i]) ) {
                                            nokeywords_group.push(config.nokeywords[i]);
                                            return 'error';
                                        }
                                    }
                                    stream.next();
                                    return null;
                                }
                            };
                        });
                        myCodeMirror = CodeMirror.fromTextArea(document.getElementById("content"), {
                            mode: "nokeywords-mode",
                            lineNumbers: true,
                            lineWrapping: true
                        });
                        myCodeMirror.on('beforeChange', function(editor) {
                            nokeywords_group = [];
                        });
                        myCodeMirror.on('changes', function(editor) {
                            content_number.html(editor.getValue().length);
                        });
                        if (config.content) {
                            myCodeMirror.setValue(config.content);
                        }
                    }
                });

                // 插入标签
                $('.insert_btn button').on('click', function(event) {
                    event.preventDefault();
                    myCodeMirror.replaceSelection(this.innerHTML + '\n');
                });

                // 去除标识
                $('#reidf').on('click', function(event) {
                    event.preventDefault();
                    let value = myCodeMirror.getValue();
                    value = value.replace(/[（|\()]?[0-9|一|二|三|四|①|②|③|⑩]+[）|\)]?[、|，|,]?/g, '');
                    myCodeMirror.setValue(value);
                });

                // 词语替换
                let replace_index;
                $('#replace').on('click', function(event) {
                    event.preventDefault();
                    replace_index = layer.open({
                        type: 1,
                        title: '词语替换',
                        area: ['490px'],
                        content: `<form class="layui-form" id="replace_form" style="padding: 20px 95px 0 0;">
                            <div class="layui-form-item">
                                <label class="layui-form-label">词语</label>
                                <div class="layui-input-block">
                                    <input type="text" name="word" lay-verify="required" placeholder="被替换的词语" class="layui-input">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">替换</label>
                                <div class="layui-input-block">
                                    <input type="text" name="replace" placeholder="用作替换的词语" class="layui-input">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                    <button class="layui-btn btn" lay-submit="" lay-filter="replace">立即提交</button>
                                </div>
                            </div>
                        </form>`
                    });
                });
                form.on('submit(replace)', function(data){
                    let value = myCodeMirror.getValue();
                    value = value.replace(new RegExp(data.field.word,'g'), data.field.replace);
                    myCodeMirror.setValue(value);
                    yunu.msg('替换完成');
                    layer.close(replace_index);
                    return false;
                });

                form.on('submit(*)', function(data){
                    nokeywords_group = Array.from(new Set(nokeywords_group));
                    if (nokeywords_group.length) {
                        yunu.msg('失败，含有违禁词---  ' + nokeywords_group.join(','), 'error');
                        return false;
                    }
                    let value = myCodeMirror.getValue();
                    if (value == '') {
                        yunu.msg('内容不能为空', 'error');
                        return false;
                    }
                    if (value.length < config.template_word_min) {
                        yunu.msg('模板字数不少于' + config.template_word_min, 'error');
                        return false;
                    }
                    if (value.length > config.template_word_max) {
                        yunu.msg('模板字数不多于' + config.template_word_max, 'error');
                        return false;
                    }
                    if (value.split('【标题】').length - 1 < config.template_title_min) {
                        yunu.msg('标题不少于' + config.template_title_min, 'error');
                        return false;
                    }
                    if (value.split('【图片】').length - 1 < config.template_img_min) {
                        yunu.msg('图片不少于' + config.template_img_min, 'error');
                        return false;
                    }
                    if (value.split('【段落】').length - 1 < config.template_section_min) {
                        yunu.msg('段落不少于' + config.template_section_min, 'error');
                        return false;
                    }
                    value = value.split('\n');
                    value = value.map((v) => {
                        return `<p>${v}</p>`;
                    })
                    data.field.content = value.join('');
                    yunu.post({
                        url: config.url,
                        field: data.field,
                        success: function (res) {
                            layer.closeAll('page');
                            yunu.msg(res.info, {
                                time: 1000,
                                end: function () {
                                    if (typeof config.success == 'function') {
                                        config.success();
                                    }
                                }
                            });
                        }
                    });
                    return false;
                });
            });
        },
        agent_edit(id){
            let tpl = data => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}">
                    <div class="layui-form-item">
                        <label class="layui-form-label">用户名</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" value="${data.username}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">站点标题</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="title" value="${data.title}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">绑定域名</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="domain" value="${data.domain}" placeholder="无需填写http://">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">背景一</label>
                        <div class="layui-input-inline mr0">
                            <input class="layui-input" type="text" name="bg1" value="${data.bg1}">
                        </div>
                        <button type="button" class="layui-btn btn br0">上传</button>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">背景二</label>
                        <div class="layui-input-inline mr0">
                            <input class="layui-input" type="text" name="bg2" value="${data.bg2}">
                        </div>
                        <button type="button" class="layui-btn btn br0">上传</button>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">背景三</label>
                        <div class="layui-input-inline mr0">
                            <input class="layui-input" type="text" name="bg3" value="${data.bg3}">
                        </div>
                        <button type="button" class="layui-btn btn br0">上传</button>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">信息审核</label>
                        <div class="layui-input-inline">
                            <input type="radio" name="review" value="0" title="需审核" ${data.review == 0 ? 'checked' : ''}>
                            <input type="radio" name="review" value="1" title="免审核" ${data.review == 1 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            yunu.post({
                url: '/' + module_name + '/Api/get_setting',
                field: {id: id},
                success: function (res) {
                    layui.use(['layer', 'form'], function(){
                        let layer = layui.layer;
                        let form = layui.form;

                        layer.open({
                            type: 1,
                            title: '修改',
                            area: ['500px'],
                            content: tpl(res.data),
                            success: function(layero, index){
                                form.render();
                            }
                        });

                        yunu.qiniu_upload_asyn(['bg1', 'bg2', 'bg3'], {
                            filters: {
                                mime_types : [
                                    {title : "Image files", extensions : "jpg,gif,png,bmp"}
                                ]
                            }
                        });

                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/User/agent_edit',
                                field: data.field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    });
                }
            });
        },
        baidu_index_editor(id){
            let config = {};

            if (id) {
                config = Object.assign(config, {
                    title: '编辑',
                    url: '/' + module_name + '/System/baidu_index_edit',
                    get_url: '/' + module_name + '/Api/get_baidu_index',
                });
            } else {
                config = Object.assign(config, {
                    title: '新增',
                    url: '/' + module_name + '/System/baidu_index_add',
                });
            }

            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    ${id ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="keyword" ${id ? `value="${data.keyword}"` : 'required lay-verify="required"'}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">所属类别</label>
                        <div class="layui-input-block">
                            <input type="radio" name="type" value="0" title="百度" ${(id && data.type == 0) || !id ? `checked=""` : ''}>
                            <input type="radio" name="type" value="1" title="百度移动" ${id && data.type == 1 ? `checked=""` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">百度指数</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="baidu_index" ${id ? `value="${data.baidu_index}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            let editor = (res = {}) => {
                layui.use(['layer', 'form'], function(){
                    let layer = layui.layer;
                    let form = layui.form;

                    layer.open({
                        type: 1,
                        title: config.title,
                        area: ['450px'],
                        content: tpl(res.data),
                        success: function(layero, index){
                            form.render();
                        }
                    });


                    form.on('submit(*)', function(data){
                        yunu.post({
                            url: config.url,
                            field: data.field,
                            success: function (res) {
                                layer.closeAll('page');
                                yunu.msg(res.info, {
                                    time: 1000,
                                    end: function () {
                                        window.location.reload();
                                    }
                                });
                            }
                        });
                        return false;
                    });
                });
            }

            if (id) {
                yunu.post({
                    url: config.get_url,
                    field: {id: id},
                    success: function (res) {
                        editor(res);
                    }
                });
            } else {
                editor();
            }
        },
        mp_editor(id){
            let config = {};

            if (id) {
                config = Object.assign(config, {
                    title: '编辑',
                    url: '/' + module_name + '/System/mp_edit',
                    get_url: '/' + module_name + '/Api/get_mp',
                });
            } else {
                config = Object.assign(config, {
                    title: '新增',
                    url: '/' + module_name + '/System/mp_add',
                });
            }

            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    ${id ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">监控点名称</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="name" ${id ? `value="${data.name}"` : 'required lay-verify="required"'}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">IP地址</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="ip" ${id ? `value="${data.ip}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">备注</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="note" ${id ? `value="${data.note}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            let editor = (res = {}) => {
                layui.use(['layer', 'form'], function(){
                    let layer = layui.layer;
                    let form = layui.form;

                    layer.open({
                        type: 1,
                        title: config.title,
                        area: ['450px'],
                        content: tpl(res.data),
                        success: function(layero, index){
                            form.render();
                        }
                    });


                    form.on('submit(*)', function(data){
                        yunu.post({
                            url: config.url,
                            field: data.field,
                            success: function (res) {
                                layer.closeAll('page');
                                yunu.msg(res.info, {
                                    time: 1000,
                                    end: function () {
                                        window.location.reload();
                                    }
                                });
                            }
                        });
                        return false;
                    });
                });
            }

            if (id) {
                yunu.post({
                    url: config.get_url,
                    field: {id: id},
                    success: function (res) {
                        editor(res);
                    }
                });
            } else {
                editor();
            }
        },
        site_editor(data){
            let config = {};

            config = Object.assign(config, {
                title: '编辑',
                url: '/' + module_name + '/Site/edit_site',
            });

            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}">
                    <div class="layui-form-item">
                        <label class="layui-form-label">每次推送数量</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="every_push_num" value="${data.every_push_num}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: config.title,
                    area: ['450px'],
                    content: tpl(data),
                    success: function(layero, index){
                        form.render();
                    }
                });


                form.on('submit(*)', function(data){
                    yunu.post({
                        url: config.url,
                        field: data.field,
                        success: function (res) {
                            layer.closeAll('page');
                            yunu.msg(res.info, {
                                time: 1000,
                                end: function () {
                                    window.location.reload();
                                }
                            });
                        }
                    });
                    return false;
                });
            });
        },
        push_close(id){
            layui.use(['layer'], function(){
                let layer = layui.layer;

                layer.confirm('您确定要关闭此任务吗，点击确定后，此任务将永久终止，不能恢复！', function(index){
                    yunu.post({
                        url: '/' + module_name + '/Api/push_close',
                        field: {id: id},
                        success: function (res) {
                            yunu.msg(res.info, {
                                time: 1000,
                                end: function () {
                                    window.location.reload();
                                }
                            });
                        }
                    });
                    layer.close(index);
                });
            });
        },
        flink_add(){
            let tpl = (data = []) => {
                return `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">域名</label>
                    <div class="layui-input-inline">
                        <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required">
                            <option value="">请选择域名</option>
                        </select>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">行业分类</label>
                    <div class="layui-input-block">
                        ${data.map((v, i) => `<input type="radio" name="monitor_point" value="${v.id}" title="${v.name}">`).join('')}
                    </div>
                </div>
                <div class="layui-form-item layui-form-text">
                    <label class="layui-form-label">关键词</label>
                    <div class="layui-input-block">
                        <textarea class="layui-textarea" name="keywords" required lay-verify="required" placeholder="一行一个，每个网站最多监控20个词，提交后无法修改，请谨慎提交"></textarea>
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                    <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                    </div>
                </div>
            </form>`
            };
            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;

                yunu.post({
                    url: '/' + module_name + '/Api/get_flink_type_select',
                    success: function (res) {
                        layer.open({
                            type: 1,
                            title: '新增',
                            area: ['780px'],
                            content: tpl(res.data)
                        });

                        form.render();

                        layui.formSelects.data('sid', 'server', {
                            url: '/' + module_name + '/Api/get_site_select'
                        });
                        $('.layui-layer-content').css('overflow', 'visible');

                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Flink/add',
                                field: data.field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        flink_editor(data = {}, obj){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '友情链接',
                    area: ['60%', '60%'],
                    content: `<div class="pl10 pr10"><a class="layui-btn btn mt10" href="javascript:;" onclick="yunu.flink_auto_record(${data.id})">自动处理记录</a><div class="flink_table" id="flink_table" lay-filter="flink_table"></div></div>`,
                    success: function(layero, index){
                        yunu.table({
                            url: '/Index/Api/linklist',
                            where: {
                                domain: data.domain,
                                protocol: data.protocol,
                                prefix: data.prefix,
                            },
                            page: false,
                            elem: '#flink_table',
                            cols: [[
                                {field: 'index', title: '序号', type: 'numbers', width: 100, sort: true}
                                ,{field: 'title', title: '站点', templet: function (d) {
                                    return '<div style="line-height: 1.5;">' + d.title + '<br><a href="' + d.url + '" target="_blank">' + d.url + '</a></div>';
                                }}
                                ,{field: 'site_exist', title: '对方友情链接', templet: function (d) {
                                    if (d.site_exist == 1) {
                                        return '有链接';
                                    } else if (d.site_exist == 2) {
                                        return '无链接';
                                    } else if (d.site_exist == 3) {
                                        return '对方站点异常';
                                    } else {
                                        return '-';
                                    }
                                }}
                                ,{title: '操作', width: 150, templet: function (d) {
                                    return '<a class="layui-btn layui-btn-xs ' + (d.site_exist != 1 ? 'btn_red' : '') + '" href="javascript:;" lay-event="link_down">下链</a>';
                                }}
                            ]]
                        }, {
                            table: 'flink_table',
                            table_del_btn: '#flink_table_del',
                            event: {
                                link_down: function (data2, obj2) {
                                    layer.confirm('您确定要下链吗', function(index){
                                        yunu.post({
                                            url: '/Index/Api/linkdel',
                                            field: {domain: data.domain, protocol: data.protocol, prefix: data.prefix, id: data2.id},
                                            success: function (res) {
                                                if (res.state == 1) {
                                                    obj2.del();
                                                    yunu.msg(res.info, {
                                                        time: 1000,
                                                        end: function () {
                                                            yunu.post({
                                                                url: '/Index/Api/unflink_sub',
                                                                field: {id: data.id},
                                                                success: function (res) {
                                                                    if (res.state == 1) {
                                                                        obj.update({unflink_num: data.unflink_num - 1});
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    });
                                                } else {
                                                    yunu.msg(res.info, 'error');
                                                }
                                            }
                                        });
                                        layer.close(index);
                                    });
                                }
                            },
                        })
                    }
                });
            });
        },
        flink_auto_record(id){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '友情链接',
                    area: ['60%', '60%'],
                    content: '<div class="pl10 pr10"><div class="flink_auto_record_table" id="flink_auto_record_table" lay-filter="flink_auto_record_table"></div></div>',
                    success: function(layero, index){
                        yunu.table({
                            url: '/Index/Api/flink_auto_record',
                            where: {
                                id: id
                            },
                            page: false,
                            elem: '#flink_auto_record_table',
                            cols: [[
                                {field: 'index', title: '序号', type: 'numbers', width: 100, sort: true}
                                ,{field: 'title', title: '站点', templet: function (d) {
                                    return '<div style="line-height: 1.5;">' + d.title + '<br><a href="' + d.url + '" target="_blank">' + d.url + '</a></div>';
                                }}
                                ,{field: 'time', title: '时间'}
                                ,{field: 'state', title: '状态', templet: function (d) {
                                    if (d.state == 1) {
                                        return '下链成功';
                                    } else {
                                        return '下链失败';
                                    }
                                }}
                            ]]
                        })
                    }
                });
            });
        },
        rank_editor(data = {}, callback = ()=>{}){
            let tpl = data => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}">
                    <input type="hidden" name="uid" value="${data.uid}">
                    <input type="hidden" name="is_edit" value="1">
                    <div class="layui-form-item">
                        <label class="layui-form-label">任务名称</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" name="client_rank_title" value="${data.client_rank_title}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">上传LOGO</label>
                        <div class="layui-input-inline mr0">
                            <input class="layui-input" type="text" name="client_rank_cover" value="${data.client_rank_cover}">
                        </div>
                        <button type="button" class="layui-btn btn br0">上传</button>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">头部广告图</label>
                        <div class="layui-input-inline mr0">
                            <input class="layui-input" type="text" name="client_rank_banner" value="${data.client_rank_banner}">
                        </div>
                        <button type="button" class="layui-btn btn br0">上传</button>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">站点域名</label>
                        <div class="layui-input-inline" style="width:65px;">
                            <input class="layui-input" type="text" name="protocol" value="${data.protocol}" placeholder="http(s)">
                        </div>
                        <div class="layui-form-mid layui-word-aux">://</div>
                        <div class="layui-input-inline" style="width:150px;">
                            <input class="layui-input" type="text" name="prefix" value="${data.prefix}" placeholder="www(顶级域名输入*)">
                        </div>
                        <div class="layui-form-mid layui-word-aux">.${data.domain}</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label pl0">报表显示主词</label>
                        <div class="layui-input-block">
                            <input type="radio" name="rank_hide" value="0" title="开启" ${data.rank_hide == 0 ? 'checked' : ''}>
                            <input type="radio" name="rank_hide" value="1" title="关闭" ${data.rank_hide == 1 ? 'checked' : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            var id = data.id;
            yunu.post({
                url: 'http://u.yunucms.com/yunuapi/rank_editor',
                field: {id: data.oid, uid: data.yunuid},
                success: function (res) {
                    // res.data.client_rank_title = data.sitename;
                    layui.use(['layer', 'form'], function(){
                        let layer = layui.layer;
                        let form = layui.form;

                        layer.open({
                            type: 1,
                            title: '修改',
                            area: ['500px'],
                            content: tpl(res.data),
                            success: function () {
                                form.render();

                                yunu.qiniu_upload_asyn(['client_rank_cover', 'client_rank_banner'], {
                                    filters: {
                                        mime_types : [
                                            {title : "Image files", extensions : "jpg,gif,png,bmp"}
                                        ]
                                    }
                                });

                                form.on('submit(*)', function(data){
                                    yunu.post({
                                        url: 'http://u.yunucms.com/yunuapi/rank_editor',
                                        field: data.field,
                                        success: function (res) {
                                            layer.closeAll('page');
                                            yunu.msg(res.info);
                                            callback();
                                        }
                                    });
                                    data.field.id = id;
                                    $.post('/' + module_name + '/Site/qsave', data.field);
                                    return false;
                                });
                            }
                        });
                    });
                }
            });
        },
        review(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 disabled_color">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-block mr50">
                            <textarea class="layui-textarea" disabled>${data.keyword}</textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">站点链接</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" value="${data.url}" readonly>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label pl0">是否允许同IP</label>
                        <div class="layui-input-block">
                            <input type="radio" value="1" title="是" ${data.same_ip == 1 ? 'checked' : ''} disabled>
                            <input type="radio" value="0" title="否" ${data.same_ip == 0 ? 'checked' : ''} disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label pl0">选择所属行业</label>
                        <div class="layui-input-inline">
                            <select disabled>
                                <option value="${data.type}">${data.type}</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item">
                         <label class="layui-form-label">审核</label>
                         <div class="layui-input-block">
                            <input type="radio" name="state" value="1" title="通过" ${data.state == 1 ? 'checked' : ''} lay-filter="state">
                            <input type="radio" name="state" value="2" title="不通过" ${data.state == 2 ? 'checked' : ''} lay-filter="state">
                         </div>
                    </div>
                    <div class="layui-form-item" id="review_note" style="display: none;">
                        <div class="layui-input-block mr50">
                            <textarea name="note" class="layui-textarea"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: ['780px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('radio(state)', function(data){
                            data.value == 2 ? $('#review_note').show() : $('#review_note').hide();
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.state) {
                                yunu.msg('请审核', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/Flink/qsave',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg('成功', {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                    $(obj.tr).find('[lay-event="review"]').remove();
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        exchange(data, obj){
            layui.use(['layer', 'table'], function(){
                let layer = layui.layer;
                let table = layui.table;

                layer.open({
                    type: 1,
                    area: '60%',
                    offset: '20%',
                    content: '<div class="pl10 pr10"><table id="exchange" lay-filter="exchange"></table></div>',
                    success: function (layero, index) {
                        table.render({
                            elem: '#exchange'
                            ,method: 'post'
                            ,url: '/' + module_name + '/Flink/exchange'
                            ,where: {sid: data.id}
                            ,cols: [[
                                {field: 'index', title: '序号', type: 'numbers', width:60, sort: true}
                                ,{field: 'keyword1', title: '关键词'}
                                ,{field: 'url1', title: '站点域名'}
                                ,{field: 'keyword2', title: '被交换关键词'}
                                ,{field: 'url2', title: '被交换站点域名'}
                                ,{field: 'add_time', title: '交换时间'}
                            ]]
                        });
                    }
                });
            });
        },
        push_config_edit(id){
            let tpl = data => {
                return `<form class="layui-form pd20">
                    <input type="hidden" name="id" value="${data.id}">
                    <div class="layui-form-item">
                        <span class="color">信息推送设置-数量设置</span>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label pl0">图片最低数量</label>
                            <div class="layui-input-inline">
                                <input class="layui-input" type="text" name="img_min" value="${data.img_min}">
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">段落数量</label>
                            <div class="layui-input-inline" style="width: 100px;">
                                <input class="layui-input" type="text" name="section_min" value="${data.section_min}">
                            </div>
                            <div class="layui-form-mid layui-word-aux">-</div>
                            <div class="layui-input-inline" style="width: 100px;">
                                <input class="layui-input" type="text" name="section_max" value="${data.section_max}">
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">字数范围</label>
                            <div class="layui-input-inline" style="width: 100px;">
                                <input class="layui-input" type="text" name="section_word_min" value="${data.section_word_min}">
                            </div>
                            <div class="layui-form-mid layui-word-aux">-</div>
                            <div class="layui-input-inline" style="width: 100px;">
                                <input class="layui-input" type="text" name="section_word_max" value="${data.section_word_max}">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label pl0">模板最低数量</label>
                            <div class="layui-input-inline">
                                <input class="layui-input" type="text" name="template_min" value="${data.template_min}">
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">字数范围</label>
                            <div class="layui-input-inline" style="width: 100px;">
                                <input class="layui-input" type="text" name="template_word_min" value="${data.template_word_min}">
                            </div>
                            <div class="layui-form-mid layui-word-aux">-</div>
                            <div class="layui-input-inline" style="width: 100px;">
                                <input class="layui-input" type="text" name="template_word_max" value="${data.template_word_max}">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <span class="color">信息推送设置-模板设置</span>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label pl0">标题最低数量</label>
                            <div class="layui-input-inline">
                                <input class="layui-input" type="text" name="template_title_min" value="${data.template_title_min}">
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label pl0">图片最低数量</label>
                            <div class="layui-input-inline">
                                <input class="layui-input" type="text" name="template_img_min" value="${data.template_img_min}">
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label pl0">段落最低数量</label>
                            <div class="layui-input-inline">
                                <input class="layui-input" type="text" name="template_section_min" value="${data.template_section_min}">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`;
            };

            yunu.post({
                url: '/' + module_name + '/Api/get_setting',
                field: {id: id},
                success: function (res) {
                    layui.use(['layer', 'form'], function(){
                        let layer = layui.layer;
                        let form = layui.form;

                        layer.open({
                            type: 1,
                            title: '修改',
                            area: ['1400px'],
                            content: tpl(res.data)
                        });

                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/User/agent_edit',
                                field: data.field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info);
                                }
                            });
                            return false;
                        });
                    });
                }
            });
        },
        link_editor(data = {}, obj){
            let config = {};

            if (data.id) {
                config = Object.assign(config, {
                    title: '编辑',
                    url: '/' + module_name + '/link/edit',
                    select_url: '/' + module_name + '/Api/get_site_select/sid/' + data.sid,
                });
            } else {
                config = Object.assign(config, {
                    title: '新增',
                    url: '/' + module_name + '/link/add',
                    select_url: '/' + module_name + '/Api/get_site_select',
                });
            }
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    ${data.id ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                    <div class="layui-form-item">
                        <label class="layui-form-label">域名</label>
                        <div class="layui-input-inline">
                            <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required">
                                <option value="">请选择域名</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="cname" required lay-verify="required" ${data.id ? `value="${data.cname}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="contact" required lay-verify="required" ${data.id ? `value="${data.contact}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="tel" required lay-verify="required" ${data.id ? `value="${data.tel}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">QQ</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="qq" required lay-verify="required" ${data.id ? `value="${data.qq}"` : ''}>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                        <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                        </div>
                    </div>
                </form>`
            };
            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;

                layer.open({
                    type: 1,
                    title: config.title,
                    area: ['520px'],
                    content: tpl(data),
                    success: function() {
                        form.render();

                        layui.formSelects.data('sid', 'server', {
                            url: config.select_url
                        });

                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: config.url,
                                field: data.field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        link_review(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 disabled_color">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">域名</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" value="${data.domain}" readonly>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司名称</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" value="${data.cname}" readonly>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">联系人</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" value="${data.contact}" readonly>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机</label>
                        <div class="layui-input-inline">
                            <input class="layui-input" type="text" value="${data.tel}" readonly>
                        </div>
                    </div>
                    <div class="layui-form-item">
                         <label class="layui-form-label">审核</label>
                         <div class="layui-input-block">
                            <input type="radio" name="state" value="1" title="通过" ${data.state == 1 ? 'checked' : ''} lay-filter="state">
                            <input type="radio" name="state" value="2" title="不通过" ${data.state == 2 ? 'checked' : ''} lay-filter="state">
                         </div>
                    </div>
                    <div class="layui-form-item" id="review_note" style="display: none;">
                        <div class="layui-input-block mr50">
                            <textarea name="remark" class="layui-textarea"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: ['780px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('radio(state)', function(data){
                            data.value == 2 ? $('#review_note').show() : $('#review_note').hide();
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.state) {
                                yunu.msg('请审核', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/Link/qsave',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg('成功', {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        link_push(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                <input type="hidden" name="id" value="${data.id}" />
                <input type="hidden" name="domain" value="${data.domain}" />
                <div class="layui-form-item">
                    <label class="layui-form-label">标题</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="title" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">文章</label>
                    <div class="layui-input-block mr50">
                        <textarea id="editor" name="content" required lay-verify="required" style="height: 400px;"></textarea>
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                    <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                    </div>
                </div>
            </form>`
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '发布软文',
                    area: ['1000px', '750px'],
                    content: tpl(data),
                    success: function() {
                        UE.delEditor('editor');
                        let ue = UE.getEditor('editor');
                        let news_count = parseInt(data.news_count);
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/link/push',
                                field: data.field,
                                success: function (res) {
                                    layer.closeAll('page');
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            obj.update({news_count: news_count++});
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        link_list(data, obj){
            layui.use(['layer', 'table'], function(){
                let layer = layui.layer;
                let table = layui.table;

                yunu.load();
                $.post('http://www.yibangyouqi.cn/index.php?s=admin/Apicontrol/articlelist', {
                    controluid: data.yunuid,
                    domain: data.domain,
                }, function(res){
                    res = JSON.parse(res);
                    if (res.state == 1) {
                        layer.open({
                            type: 1,
                            area: '60%',
                            offset: '20%',
                            content: '<div class="pl10 pr10"><table id="link_list" lay-filter="link_list"></table></div>',
                            success: function (layero, index) {
                                table.render({
                                    elem: '#link_list'
                                    ,data: res.data
                                    ,cols: [[
                                        {field: 'title', title: '文章标题'}
                                        ,{field: 'create_time', title: '发布时间'}
                                        ,{field: 'url', title: '操作', templet: function (d) {
                                            return '<a href="'+d.url+'" target="_blank">查看</a>';
                                        }}
                                    ]]
                                });
                            }
                        });
                    } else{
                        yunu.msg(res.info, 'error');
                    }
                }).always(function() { yunu.load('close'); });
            });
        },
        create_url(callback){
            let tpl = () => {
                return `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">网址格式</label>
                    <div class="layui-input-block mr50">
                        <input class="layui-input" type="text" name="url" lay-verify="required" placeholder="http://www.xxx.com/news/page/[page]">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">数字</label>
                    <div class="layui-form-mid">从</div>
                    <div class="layui-input-inline" style="width: 50px;">
                        <input class="layui-input" type="number" name="num_start" value="1">
                    </div>
                    <div class="layui-form-mid">到</div>
                    <div class="layui-input-inline" style="width: 50px;">
                        <input class="layui-input" type="number" name="num_end" value="1">
                    </div>
                    <div class="layui-form-mid">递增数量</div>
                    <div class="layui-input-inline" style="width: 50px;">
                        <input class="layui-input" type="text" name="increase" value="1">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                    <button class="layui-btn btn" lay-submit lay-filter="create_url">提交</button>
                    </div>
                </div>
            </form>`
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '批量添加',
                    area: ['750px'],
                    content: tpl(),
                    success: function(layero, index) {
                        form.on('submit(create_url)', function(data){
                            var url = data.field.url;
                            var start = parseInt(data.field.num_start);
                            var end = parseInt(data.field.num_end);
                            var increase = parseInt(data.field.increase);
                            var result = [];
                            var order = start <= end;
                            var num = 10;
                            while (num > 0) {
                                if ((order && start > end) || (!order && start < end)) {
                                    num = 0;
                                } else {
                                    result.push(url.replace('[page]', start));
                                    start += increase;
                                    num--;
                                }
                            }
                            result = result.join('\r\n');
                            callback(result);
                            layer.close(index);
                            return false;
                        });
                    }
                });
            });
        },
        collect_replace(id, callback) {
            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '词语替换',
                    area: ['490px'],
                    content: `<form class="layui-form" id="replace_form" style="padding: 20px 95px 0 0;">
                        <input type="hidden" name="id" value="${id}" />
                        <div class="layui-form-item">
                            <label class="layui-form-label">词语</label>
                            <div class="layui-input-block">
                                <input type="text" name="word" placeholder="被替换的词语" class="layui-input">
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <label class="layui-form-label">替换</label>
                            <div class="layui-input-block">
                                <input type="text" name="replace" placeholder="用作替换的词语" class="layui-input">
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <label class="layui-form-label">类型</label>
                            <div class="layui-input-block">
                                <input type="checkbox" name="type[]" title="标题" value="0" lay-skin="primary">
                                <input type="checkbox" name="type[]" title="内容" value="1" lay-skin="primary" checked>
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <div class="layui-input-block">
                                <button class="layui-btn btn" lay-submit="" lay-filter="replace">立即提交</button>
                            </div>
                        </div>
                    </form>`,
                    success: function(layero, index) {
                        form.render();
                        form.on('submit(replace)', function(data){
                            var url = data.field.url;
                            yunu.post({
                                url: '/' + module_name + '/Collect/replace',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    if (callback) {
                                        callback();
                                    }
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        collect_data_list(pid, state = 0){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                var col = [
                    {field: 'index', title: '序号', type: 'numbers', width: 100, sort: true}
                    ,{field: 'title', title: '标题名称'}
                    ,{field: 'time', title: '发布时间'}
                ];
                if (state == 0) {
                    col.push({title: '操作', width: 150, templet: function (d) {
                        return '<a class="layui-btn layui-btn-xs btn" href="javascript:;" lay-event="edit">修改</a>';
                    }});
                } else {
                    col.push({title: '查看', width: 150, templet: function (d) {
                        return '<a class="layui-btn layui-btn-xs btn" href="'+d.cms_url+'" target="_blank">查看</a>';
                    }});
                }

                layer.open({
                    type: 1,
                    area: ['60%', '60%'],
                    content: `<div class="pl10 pr10"><div class="collect_data_table" id="collect_data_table" lay-filter="collect_data_table"></div></div>`,
                    success: function(layero, index){
                        yunu.table({
                            url: '/' + module_name + '/Collect/collect_data_list',
                            where: {
                                pid: pid,
                                state: state,
                            },
                            elem: '#collect_data_table',
                            cols: [col]
                        }, {
                            table: 'collect_data_table',
                            event: {
                                edit: function (data, obj) {
                                    yunu.collect_data_show(data, function () {
                                        table.reload('collect_data_table');
                                    });
                                }
                            },
                        })
                    }
                });
            });
        },
        auto_collect_data_list(pid, state = 0){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                var col = [
                    {field: 'index', title: '序号', type: 'numbers', width: 100, sort: true}
                    ,{field: 'title', title: '标题名称'}
                    ,{field: 'time', title: '发布时间'}
                ];
                if (state == 0) {
                    col.push({title: '操作', width: 150, templet: function (d) {
                        return '<a class="layui-btn layui-btn-xs btn" href="javascript:;" lay-event="edit">修改</a>';
                    }});
                } else {
                    col.push({title: '查看', width: 150, templet: function (d) {
                        return '<a class="layui-btn layui-btn-xs btn" href="'+d.cms_url+'" target="_blank">查看</a>';
                    }});
                }

                layer.open({
                    type: 1,
                    area: ['60%', '60%'],
                    content: `<div class="pl10 pr10"><div class="auto_collect_data_table" id="auto_collect_data_table" lay-filter="auto_collect_data_table"></div></div>`,
                    success: function(layero, index){
                        yunu.table({
                            url: '/' + module_name + '/Collect/auto_collect_data_list',
                            where: {
                                pid: pid,
                                state: state,
                            },
                            elem: '#auto_collect_data_table',
                            cols: [col]
                        }, {
                            table: 'auto_collect_data_table',
                            event: {
                                edit: function (data, obj) {
                                    yunu.collect_data_show(data, function () {
                                        table.reload('auto_collect_data_table');
                                    });
                                }
                            },
                        })
                    }
                });
            });
        },
        collect_data_show(data, callback){
            layui.use(['layer', 'form', 'table', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;
                let laydate = layui.laydate;

                layer.open({
                    type: 1,
                    area: ['60%', '60%'],
                    content: `
                        <form class="layui-form pt20">
                            <input type="hidden" name="id" value="${data.id}">
                            <div class="layui-form-item">
                                <label class="layui-form-label">标题</label>
                                <div class="layui-input-inline">
                                    <input class="layui-input" type="text" name="title" lay-verify="required" value="${data.title}">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">时间</label>
                                <div class="layui-input-inline">
                                    <input class="layui-input" id="time" type="text" name="time" lay-verify="required" value="${data.time}">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">内容</label>
                                <div class="layui-input-block mr50">
                                    <textarea id="editor" name="content" required lay-verify="required" style="height: 400px;">${data.content}</textarea>
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                <button class="layui-btn btn" lay-submit="" lay-filter="collect_data_show">保存</button>
                                <a class="layui-btn btn" href="${'/' + module_name + '/Collect/preview/id/' + data.id}" target="_blank">预览</a>
                                </div>
                            </div>
                        </form>
                    `,
                    success: function(layero, index){
                        UE.delEditor('editor');
                        var ue = UE.getEditor('editor', {
                            toolbars: [[
                                // 'fullscreen',
                                'source',
                                '|',
                                'undo', 'redo',
                                '|',
                                'bold', 'italic', 'underline',
                                '|',
                                'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
                                'link'
                            ]]
                        });
                        laydate.render({
                            elem: '#time'
                            ,type: 'datetime'
                            ,btns: ['clear', 'confirm']
                        });
                        form.on('submit(collect_data_show)', function(data){
                            yunu.post({
                                url: '/Index/Collect/collect_data_edit',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            if (typeof callback == 'function') {
                                                callback(res);
                                            }
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        img_editor(callback, task_type = 0){
            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }
            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;
                let encrypt = ''

                layer.open({
                    type: 1,
                    title: '选择图片',
                    area: ['1280px', '80%'],
                    content: `<div class="pd20">
                        <div class="table_top mb20">
                            <div>
                                <a class="layui-btn btn" id="fetch_img" href="javascript:;">获取图片</a>
                                <a class="layui-btn btn btn_red" id="del_img" href="javascript:;">批量删除</a>
                            </div>
                            <div>
                                <a class="layui-btn btn" id="select_img_all" href="javascript:;">全选</a>
                            </div>
                        </div>
                        <div class="material_img" id="material_img"></div>
                        <div class="table_top mb20">
                            <div>
                            </div>
                            <div>
                                <a class="layui-btn btn" id="checked_img" href="javascript:;">立即处理</a>
                            </div>
                        </div>
                    </div>`,
                    success: function(layero, index){
                        material_img_list();

                        $('#fetch_img').on('click', function() {
                            layer.open({
                                type: 1,
                                title: '选择域名',
                                area: ['400px', '250px'],
                                content: `<form class="layui-form pt20">
                                    <div class="layui-form-item">
                                        <label class="layui-form-label">选择域名</label>
                                        <div class="layui-input-inline">
                                            <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required">
                                                <option value="">请选择域名</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="layui-form-item">
                                        <div class="layui-input-block">
                                        <button class="layui-btn btn" lay-submit lay-filter="sid">下一步</button>
                                        </div>
                                    </div>
                                </form>`,
                                success: function(layero, index){
                                    form.render();
                                    layui.formSelects.data('sid', 'server', {
                                        url: '/' + module_name + '/Api/get_site_select'
                                    });
                                    form.on('submit(sid)', function(data){
                                        if (!(data.field.sid > 0)) {
                                            yunu.msg('请选择域名', 'error');
                                            return false;
                                        }
                                        yunu.load();
                                        $.post('/' + module_name + '/Api/get_site_url', data.field, function (res) {
                                            if (res.state == 1) {
                                                layer.close(index);
                                                encrypt = res.encrypt
                                                fetch_img_open(res.data + '/index.php?s=/admin/api/browsefile/stype/picture')
                                            } else {
                                                yunu.msg(res.info, 'error');
                                            }
                                        }).always(function() {
                                            yunu.load('close');
                                        });
                                        return false;
                                    });
                                }
                            });
                        });
                        $('body').on('click', '.purl',function () {
                            fetch_img_open($(this).attr('data-url'))
                        }).on('click', '.fetch_img',function () {
                            yunu.post({
                                url: '/' + module_name + '/Collect/img_add',
                                field: {
                                    url: [$(this).attr('data-url')],
                                    type: task_type
                                },
                                success: function (res) {
                                    if (res.successful > 0 ) {
                                        yunu.msg('上传成功');
                                    } else {
                                        yunu.msg('上传失败', false);
                                    }
                                }
                            });
                        });

                        // 图片预览
                        $('#material_img').on('click', '.img_box', function () {
                            $(this).next('img').trigger('click');
                        });

                        // 图片删除
                        var img_del = function(id) {
                            layer.confirm('是否确认删除', {icon: 3, title:'提示'}, function(index){
                                layer.close(index);
                                yunu.post({
                                    url: '/' + module_name + '/Collect/img_del',
                                    field: {id: id},
                                    success: function (res) {
                                        yunu.msg(res.info);
                                        material_img_list();
                                    }
                                });
                            });
                        }
                        $('#material_img').on('click', '.del', function () {
                            var id = $(this).closest('li').attr('data-id');
                            img_del(id);
                        });
                        $('#del_img').on('click', function () {
                            var checked = $('[name="img_id[]"]:checked');
                            if (checked.length) {
                                var id = checked.map(function(index, el) {
                                    return $(el).val();
                                }).get().join(',');
                                img_del(id);
                            } else {
                                yunu.msg('请选中', 'error');
                            }
                        });
                        // 图片全选
                        var select_img_all = false;
                        $('#select_img_all').on('click', function() {
                            select_img_all = !select_img_all;
                            if (select_img_all) {
                                $('[name="img_id[]"]').prop('checked', true);
                            } else {
                                $('[name="img_id[]"]').removeProp('checked');
                            }
                        });

                        $('#checked_img').on('click', function() {
                            var checked = $('[name="img_id[]"]:checked');
                            if (checked.length) {
                                var id_arr = checked.map(function(index, el) {
                                    return $(el).val();
                                }).get();
                                var id = id_arr.join(',');
                                layer.close(index);
                                if (typeof callback == 'function') {
                                    callback(id);
                                } else {
                                    $('input[name="img"]').val(id);
                                    yunu.msg('已选择'+id_arr.length+'张');
                                }
                            } else {
                                yunu.msg('请选中', 'error');
                            }
                        });
                    }
                });

                function material_img_list() {
                    yunu.post({
                        url: '/' + module_name + '/Collect/material_img',
                        field: {pid: 0, type: task_type ? task_type : 0},
                        success: function (res) {
                            let el = $('<ul></ul>');
                            $.each(res.data, function (i, v) {
                                let item = $(`<li data-id="${v.id}">
                                    <div class="img_box" style="background-image: url(${v.url});"></div>
                                    <img layer-src="${v.url}" src="${v.url}">
                                    <label class="diqu_checkbox"><input type="checkbox" name="img_id[]" value="${v.id}" lay-ignore checked disabled><i class="layui-icon">&#xe605;</i></label>
                                    <a class="del" href="javascript:;"><i class="layui-icon">&#xe640;</i>删除</a>
                                </li>`)
                                el.append(item);
                            });
                            if (!res.data.length) {
                                el = $('<p style="color: red;font-weight: bold;text-align: center;padding-bottom: 20px;">没有数据</p>');
                            }
                            $('#material_img').html(el);
                            layer.photos({
                                photos: '#material_img'
                            });
                        }
                    });
                }

                function fetch_img_template (data) {
                    var dom = '<div class="fl mg10 tac ellipsis" style="width: 100px;">' +
                        '<a class="purl" data-url="'+data.purl+'" href="javascript:;" title="上级目录"><img src="/Public/Public/img/back.jpg"><br>上级目录</a>' +
                    '</div>';
                    $.each(data.infolist, function (i, v) {
                        if (v.isDir) {
                            dom += '<div class="fl mg10 tac ellipsis" style="width: 100px;">' +
                                '<a class="purl" data-url="'+v.url+'" href="javascript:;" title="'+v.filename+'"><img src="/Public/Public/img/folder.jpg"><br>'+v.filename+'</a>' +
                            '</div>';
                        } else {
                            dom += '<div class="fl mg10 tac ellipsis" style="width: 100px;">' +
                                '<a class="fetch_img" data-url="'+v.url+'" href="javascript:;" title="'+v.filename+'"><img src="'+v.url+'" width="100" height="100"><br>'+v.filename+'</a>' +
                            '</div>';
                        }
                    });
                    return dom;
                }

                function fetch_img_open (url) {
                    yunu.load();
                    url += '/encrypt/' + encrypt
                    $.get(url)
                    .done(function (res) {
                        layer.close(layer.index - 1);
                        res = JSON.parse(res);
                        layer.open({
                            type: 1,
                            anim: -1,
                            title: '选择站内图片',
                            area: ['80%', '60%'],
                            content: fetch_img_template(res),
                            cancel: function () {
                                material_img_list();
                            }
                        });
                    })
                    .fail(function() { yunu.msg('失败', 'error'); })
                    .always(function() { yunu.load('close'); });
                }
            });
        },
        manual_push_task_add(id, callback){
            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }
            layui.use(['layer', 'form', 'formSelects', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;
                let laydate = layui.laydate;

                layer.open({
                    type: 1,
                    area: ['600px', '60%'],
                    content: `
                        <form class="layui-form pt30">
                        <input type="hidden" name="data_id" value="${id}">
                        <div class="layui-form-item">
                            <label class="layui-form-label">任务名称</label>
                            <div class="layui-input-inline">
                                <input class="layui-input" type="text" name="name" lay-verify="required">
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <label class="layui-form-label">选择域名</label>
                            <div class="layui-input-inline">
                                <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required">
                                    <option value="">请选择域名</option>
                                </select>
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <label class="layui-form-label">发布栏目</label>
                            <div class="layui-input-inline">
                                <select name="cid" lay-verify="required" lay-search lay-verify="required">
                                </select>
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <label class="layui-form-label">发布方式</label>
                            <div class="layui-input-inline">
                                <input type="radio" name="type" value="0" title="立即发布" lay-filter="type" checked>
                                <input type="radio" name="type" value="1" title="定时发布" lay-filter="type">
                            </div>
                        </div>
                        <div class="layui-form-item date_type">
                            <label class="layui-form-label">时间类型</label>
                            <div class="layui-input-block">
                                <input type="radio" name="date_type" value="0" title="原文发布时间" lay-filter="date_type" checked>
                                <input type="radio" name="date_type" value="1" title="自定义时间" lay-filter="date_type">
                            </div>
                        </div>
                        <div class="type_switch" style="display: none;">
                            <div class="layui-form-item day_num">
                                <label class="layui-form-label">每天条数</label>
                                <div class="layui-input-inline">
                                    <input class="layui-input" type="text" name="day_num" lay-verify="required" value="10">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">发布时间</label>
                                <div class="layui-input-inline">
                                    <input class="layui-input" id="push_time" type="text" name="push_time" lay-verify="required" value="08:00:00">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">间隔时间</label>
                                <div class="layui-input-inline">
                                    <input class="layui-input" type="text" name="interval_time" lay-verify="required" value="${Math.floor(300 - Math.random()*240)}">
                                </div>
                                <div class="layui-form-mid layui-word-aux">秒</div>
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <label class="layui-form-label">是否推荐</label>
                            <div class="layui-input-inline">
                                <input type="radio" name="flag" value="1" title="是" checked>
                                <input type="radio" name="flag" value="0" title="否">
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="manual_push_task_add">保存</button>
                            </div>
                        </div>
                    </form>
                    `,
                    success: function(layero, index){
                        form.render();
                        layui.formSelects.data('sid', 'server', {
                            url: '/' + module_name + '/Api/get_site_select'
                        });
                        laydate.render({
                            elem: '#push_time'
                            ,type: 'time'
                            ,btns: ['clear', 'confirm']
                        });

                        form.on('radio(type)', function(data){
                            if (data.value > 0) {
                                $('.type_switch').show();
                                $('.day_num').show();
                                $('.date_type').hide();
                            } else {
                                $('.type_switch').hide();
                                $('.date_type').show();
                            }
                        });

                        form.on('radio(date_type)', function(data){
                            if (data.value > 0) {
                                $('.type_switch').show();
                                $('.day_num').hide();
                            } else {
                                $('.type_switch').hide();
                            }
                        });
                        // 所属栏目
                        var cid_select = $('select[name="cid"]');
                        var cid_select_append = function (data, layer) {
                            layer = layer||0;
                            var symbol = '';
                            for (var i = 0; i < layer; i++) {
                                symbol += '--';
                            }
                            $.each(data, function (i, v) {
                                if (typeof v.child != 'object') {
                                    v = JSON.parse(v);
                                }
                                if (v.id == "{$data.cid}") {
                                    cid_select.append('<option value="'+v.id+'" selected>'+symbol+v.title+'</option>');
                                } else {
                                    cid_select.append('<option value="'+v.id+'">'+symbol+v.title+'</option>');
                                }
                                if (v.child.length) {
                                    cid_select_append(v.child, layer+1);
                                }
                            });
                        }
                        layui.formSelects.on('sid', function(id, vals, val, isAdd, isDisabled){
                            if (val.value > 0) {
                                yunu.load();
                                $.ajax({
                                    url: '/' + module_name + '/Collect/site_catlist',
                                    type: "POST",
                                    data: {id: val.value}
                                }).done(function(res) {
                                    if (res.state == 1) {
                                        cid_select.html('');
                                        cid_select_append(res.data);
                                        form.render('select');
                                    }else{
                                        layer.msg(res.info, {icon: 2});
                                    }
                                }).fail(function(jqXHR, textStatus) {
                                    layer.msg('授权域名栏目获取失败', {icon: 2});
                                }).always(function () {
                                    yunu.load('close');
                                });
                            } else {
                                cid_select.html('');
                                form.render('select');
                            }
                        });
                        form.on('submit(manual_push_task_add)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Collect/manual_push_task_add',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    if (typeof callback == 'function') {
                                        callback(res);
                                    }
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        manual_task_add(id, callback){
            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }
            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;

                layer.open({
                    type: 1,
                    area: ['600px'],
                    content: `
                        <form class="layui-form pt20">
                            <input type="hidden" name="data_id" value="{$id}">
                            <div class="layui-form-item">
                                <label class="layui-form-label">任务名称</label>
                                <div class="layui-input-inline">
                                    <input class="layui-input" type="text" name="name" lay-verify="required">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">规则类型</label>
                                <div class="layui-input-inline">
                                    <select name="rid" xm-select="rid" xm-select-search="" xm-select-radio required lay-verify="required">
                                        <option value="">请选择</option>
                                    </select>
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">域名</label>
                                <div class="layui-input-inline w334">
                                    <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required">
                                        <option value="">请选择域名</option>
                                    </select>
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                <button class="layui-btn btn" lay-submit="" lay-filter="*">保存</button>
                                </div>
                            </div>
                        </form>
                    `,
                    success: function(layero, index){
                        form.render();
                        layui.formSelects.data('rid', 'server', {
                            url: '/' + module_name + '/Api/get_rule_select'
                        });
                        layui.formSelects.data('sid', 'server', {
                            url: '/' + module_name + '/Api/get_site_select'
                        });

                        form.on('submit(*)', function(data){
                            yunu.load();
                            $.post('/' + module_name + '/Collect/manual_task_add', data.field, function(res){
                                if (res.state == 1) {
                                    yunu.msg(res.info, {
                                        time: 1000,
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }else{
                                    yunu.msg(res.info, 'error');
                                }
                            }).always(function() { yunu.load('close'); });
                            return false;
                        });
                    }
                });
            });
        },
        cloud_query(data, obj, callback = ()=>{}){
            layui.use(['layer', 'form', 'element'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let element = layui.element;

                layer.open({
                    type: 1,
                    area: ['660px'],
                    content: `
                        <form class="layui-form pt20">
                            <input type="hidden" name="sid" value="${data.id}">
                            <div class="layui-form-item">
                                <label class="layui-form-label">查询方式</label>
                                <div class="layui-input-block">
                                    <input type="radio" name="type1" value="0" title="获取系统词" lay-filter="type1" checked>
                                    <input type="radio" name="type1" value="1" title="导入关键词" lay-filter="type1">
                                </div>
                            </div>
                            <div class="layui-form-item type1_0">
                                <label class="layui-form-label">获词方式</label>
                                <div class="layui-input-block">
                                    <input type="checkbox" name="type2[]" title="获取CMS关键词" value="0" lay-skin="primary" checked>
                                    <input type="checkbox" name="type2[]" title="获取上次查询关键词" value="1" lay-skin="primary" checked>
                                </div>
                            </div>
                            <div class="layui-form-item type1_1" style="display: none;">
                                <label class="layui-form-label">关键词</label>
                                <div class="layui-input-block mr50">
                                    <textarea class="layui-textarea" name="keywords" placeholder="一行一个，每个网站最多提交5万个词"></textarea>
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">百度名称</label>
                                <div class="layui-input-inline">
                                    <input class="layui-input" type="text" name="baidu_name" placeholder="请输入账号">
                                </div>
                                <div class="layui-form-mid layui-word-aux">
                                    原熊掌号名称，不填写会造成监控不准确
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <div class="layui-input-block">
                                <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                                </div>
                            </div>
                        </form>
                    `,
                    success: function(layero, index){
                        form.render();

                        $('.layui-layer-content').height('auto');

                        form.on('radio(type1)', function(data){
                            if (data.value == 1) {
                                $('.type1_1').show();
                                $('.type1_0').hide();
                            } else {
                                $('.type1_0').show();
                                $('.type1_1').hide();
                            }
                        });

                        form.on('submit(*)', function(data){
                            yunu.load();
                            $.post('/' + module_name + '/Site/cloud_query_task_add', data.field, function(res){
                                var task_id = res.id;
                                if (res.state == 1) {
                                    let timer = null;
                                    layer.close(index);
                                    layer.open({
                                        title: '开始处理',
                                        type: 1,
                                        area: ['550px'],
                                        content: '<div class="pd20">' +
                                            '<div class="layui-progress layui-progress-big" lay-showPercent="true" lay-filter="original">' +
                                                '<div class="layui-progress-bar layui-bg-blue" id="progress" lay-percent="0%"></div>' +
                                            '</div><div class="tac pt20" id="progress_info">数据预处理</div>' +
                                        '</div>',
                                        success: function(layero, index){
                                            element.render('progress');

                                            timer = setInterval(function () {
                                                $.post('/' + module_name + '/Site/cloud_query_task_state_check', {id: task_id}, function (res) {
                                                    $('#progress_info').html(res.info);
                                                    if (res.state == 1) {
                                                        $('#progress').attr('lay-percent', res.progress + '%');
                                                        element.render('progress');
                                                        if (res.progress >= 100) {
                                                            clearInterval(timer);
                                                            setTimeout(function () {
                                                                layer.close(index);
                                                                layer.confirm(res.tip, {icon: 3, title:'提示'}, function(index){
                                                                    layer.close(index);
                                                                    yunu.post({
                                                                        url: '/' + module_name + '/Site/cloud_query_task_start',
                                                                        field: {id: task_id},
                                                                        success: function (res) {
                                                                            yunu.msg(res.info, callback);
                                                                        }
                                                                    });
                                                                });
                                                            }, 3000)
                                                        }
                                                    }
                                                });
                                            }, 3000);
                                        },
                                        end: function () {
                                            clearInterval(timer);
                                        }
                                    });
                                }else{
                                    yunu.msg(res.info, 'error');
                                }
                            }).always(function() { yunu.load('close'); });
                            return false;
                        });
                    }
                });
            });
        },
        query_keywords_editor(data, obj, callback = ()=>{}){
            let config = {};

            if (data) {
                config = Object.assign(config, {
                    title: '编辑',
                    url: '/' + module_name + '/QueryKeywords/edit',
                    select_url: '/' + module_name + '/Api/get_site_select/sid/' + data.sid,
                });
            } else {
                config = Object.assign(config, {
                    title: '新增',
                    url: '/' + module_name + '/QueryKeywords/add',
                    select_url: '/' + module_name + '/Api/get_site_select',
                });
            }
            let tpl = (data = {}) => {
                return `
                    <form class="layui-form pt20">
                        ${data.id ? `<input type="hidden" name="id" value="${data.id}">` : ''}
                        <div class="layui-form-item">
                            <label class="layui-form-label">域名</label>
                            <div class="layui-input-inline w334">
                                <select name="sid" xm-select="sid" xm-select-search="" xm-select-radio required lay-verify="required" ${data.id ? 'disabled' : ''}>
                                    <option value="">请选择域名</option>
                                </select>
                            </div>
                        </div>
                        <div class="layui-form-item type1_1">
                            <label class="layui-form-label">关键词</label>
                            <div class="layui-input-block mr50">
                                <textarea class="layui-textarea" name="keywords" placeholder="一行一个，每个网站最多提交5万个词" required lay-verify="required">${data.id ? `${data.keywords}` : ''}</textarea>
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <label class="layui-form-label">百度名称</label>
                            <div class="layui-input-inline">
                                <input class="layui-input" type="text" name="baidu_name" placeholder="请输入账号" value="${data.id ? `${data.baidu_name}` : ''}">
                            </div>
                            <div class="layui-form-mid layui-word-aux">
                                原熊掌号名称，不填写会造成监控不准确
                            </div>
                        </div>
                        <div class="layui-form-item">
                            <div class="layui-input-block">
                            <button class="layui-btn btn" lay-submit="" lay-filter="*">提交</button>
                            </div>
                        </div>
                    </form>
                `;
            };
            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }
            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;

                let open = function (data) {
                    layer.open({
                        type: 1,
                        title: config.title,
                        area: ['660px'],
                        content: tpl(data),
                        success: function(layero, index){
                            form.render();

                            layui.formSelects.data('sid', 'server', {
                                url: config.select_url
                            });

                            form.on('submit(*)', function(data){
                                yunu.post({
                                    url: config.url,
                                    field: data.field,
                                    success: function (res) {
                                        layer.closeAll('page');
                                        yunu.msg(res.info, {
                                            time: 1000,
                                            end: function () {
                                                window.location.reload();
                                            }
                                        });
                                    }
                                });
                                return false;
                            });
                        }
                    });
                }
                if (data) {
                    yunu.post({
                        url: '/' + module_name + '/QueryKeywords/find',
                        field: {id: data.id},
                        success: function (res) {
                            data.keywords = res.data.keywords
                            open(data)
                        }
                    })
                } else {
                    open()
                }
            });
        },
    });

    $(() => {
        yunu.aside();
    });

})(jQuery, window, document);
